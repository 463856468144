*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root, [data-theme] {
  background-color: hsla(var(--b1) / var(--tw-bg-opacity, 1));
  color: hsla(var(--bc) / var(--tw-text-opacity, 1));
}

html {
  -webkit-tap-highlight-color: transparent;
}

:root {
  color-scheme: light;
  --pf: 258.89 94.378% 40.941%;
  --sf: 314 100% 37.647%;
  --af: 174 60% 40.784%;
  --nf: 219 14.085% 22.275%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 258.89 94.378% 51.176%;
  --pc: 0 0% 100%;
  --s: 314 100% 47.059%;
  --sc: 0 0% 100%;
  --a: 174 60% 50.98%;
  --ac: 174.71 43.59% 15.294%;
  --n: 219 14.085% 27.843%;
  --nc: 0 0% 100%;
  --b1: 0 0% 100%;
  --b2: 0 0% 94.902%;
  --b3: 180 1.9608% 90%;
  --bc: 215 27.907% 16.863%;
}

@media (prefers-color-scheme: dark) {
  :root {
    color-scheme: dark;
    --pf: 262.35 80.315% 40.157%;
    --sf: 315.75 70.196% 40%;
    --af: 174.69 70.335% 32.784%;
    --in: 198 93% 60%;
    --su: 158 64% 52%;
    --wa: 43 96% 56%;
    --er: 0 91% 71%;
    --inc: 198 100% 12%;
    --suc: 158 100% 10%;
    --wac: 43 100% 11%;
    --erc: 0 100% 14%;
    --rounded-box: 1rem;
    --rounded-btn: .5rem;
    --rounded-badge: 1.9rem;
    --animation-btn: .25s;
    --animation-input: .2s;
    --btn-text-case: uppercase;
    --btn-focus-scale: .95;
    --border-btn: 1px;
    --tab-border: 1px;
    --tab-radius: .5rem;
    --p: 262.35 80.315% 50.196%;
    --pc: 0 0% 100%;
    --s: 315.75 70.196% 50%;
    --sc: 0 0% 100%;
    --a: 174.69 70.335% 40.98%;
    --ac: 0 0% 100%;
    --n: 218.18 18.033% 11.961%;
    --nf: 222.86 17.073% 8.0392%;
    --nc: 220 13.376% 69.216%;
    --b1: 220 17.647% 20%;
    --b2: 220 17.241% 17.059%;
    --b3: 218.57 17.949% 15.294%;
    --bc: 220 13.376% 69.216%;
  }
}

[data-theme="light"] {
  color-scheme: light;
  --pf: 258.89 94.378% 40.941%;
  --sf: 314 100% 37.647%;
  --af: 174 60% 40.784%;
  --nf: 219 14.085% 22.275%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 258.89 94.378% 51.176%;
  --pc: 0 0% 100%;
  --s: 314 100% 47.059%;
  --sc: 0 0% 100%;
  --a: 174 60% 50.98%;
  --ac: 174.71 43.59% 15.294%;
  --n: 219 14.085% 27.843%;
  --nc: 0 0% 100%;
  --b1: 0 0% 100%;
  --b2: 0 0% 94.902%;
  --b3: 180 1.9608% 90%;
  --bc: 215 27.907% 16.863%;
}

[data-theme="dark"] {
  color-scheme: dark;
  --pf: 262.35 80.315% 40.157%;
  --sf: 315.75 70.196% 40%;
  --af: 174.69 70.335% 32.784%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 262.35 80.315% 50.196%;
  --pc: 0 0% 100%;
  --s: 315.75 70.196% 50%;
  --sc: 0 0% 100%;
  --a: 174.69 70.335% 40.98%;
  --ac: 0 0% 100%;
  --n: 218.18 18.033% 11.961%;
  --nf: 222.86 17.073% 8.0392%;
  --nc: 220 13.376% 69.216%;
  --b1: 220 17.647% 20%;
  --b2: 220 17.241% 17.059%;
  --b3: 218.57 17.949% 15.294%;
  --bc: 220 13.376% 69.216%;
}

[data-theme="cupcake"] {
  color-scheme: light;
  --pf: 183.03 47.368% 47.216%;
  --sf: 338.25 71.429% 62.431%;
  --af: 39 84.112% 46.431%;
  --nf: 280 46.479% 11.137%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --pc: 183.03 100% 11.804%;
  --sc: 338.25 100% 15.608%;
  --ac: 39 100% 11.608%;
  --nc: 280 82.688% 82.784%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --p: 183.03 47.368% 59.02%;
  --s: 338.25 71.429% 78.039%;
  --a: 39 84.112% 58.039%;
  --n: 280 46.479% 13.922%;
  --b1: 24 33.333% 97.059%;
  --b2: 26.667 21.951% 91.961%;
  --b3: 22.5 14.286% 89.02%;
  --bc: 280 46.479% 13.922%;
  --rounded-btn: 1.9rem;
  --tab-border: 2px;
  --tab-radius: .5rem;
}

[data-theme="bumblebee"] {
  color-scheme: light;
  --pf: 41.124 74.167% 42.353%;
  --sf: 49.901 94.393% 46.431%;
  --af: 240 33.333% 11.294%;
  --nf: 240 33.333% 11.294%;
  --b2: 0 0% 90%;
  --b3: 0 0% 81%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --bc: 0 0% 20%;
  --ac: 240 60.274% 82.824%;
  --nc: 240 60.274% 82.824%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 41.124 74.167% 52.941%;
  --pc: 240 33.333% 14.118%;
  --s: 49.901 94.393% 58.039%;
  --sc: 240 33.333% 14.118%;
  --a: 240 33.333% 14.118%;
  --n: 240 33.333% 14.118%;
  --b1: 0 0% 100%;
}

[data-theme="emerald"] {
  color-scheme: light;
  --pf: 141.18 50% 48%;
  --sf: 218.88 96.078% 48%;
  --af: 9.8901 81.25% 44.863%;
  --nf: 219.23 20.312% 20.078%;
  --b2: 0 0% 90%;
  --b3: 0 0% 81%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --btn-text-case: uppercase;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 141.18 50% 60%;
  --pc: 151.11 28.421% 18.627%;
  --s: 218.88 96.078% 60%;
  --sc: 210 20% 98.039%;
  --a: 9.8901 81.25% 56.078%;
  --ac: 210 20% 98.039%;
  --n: 219.23 20.312% 25.098%;
  --nc: 210 20% 98.039%;
  --b1: 0 0% 100%;
  --bc: 219.23 20.312% 25.098%;
  --animation-btn: 0;
  --animation-input: 0;
  --btn-focus-scale: 1;
}

[data-theme="corporate"] {
  color-scheme: light;
  --pf: 229.09 95.652% 51.137%;
  --sf: 214.91 26.316% 47.216%;
  --af: 154.2 49.02% 48%;
  --nf: 233.33 27.273% 10.353%;
  --b2: 0 0% 90%;
  --b3: 0 0% 81%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --pc: 229.09 100% 92.784%;
  --sc: 214.91 100% 11.804%;
  --ac: 154.2 100% 12%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --btn-text-case: uppercase;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 229.09 95.652% 63.922%;
  --s: 214.91 26.316% 59.02%;
  --a: 154.2 49.02% 60%;
  --n: 233.33 27.273% 12.941%;
  --nc: 210 38.462% 94.902%;
  --b1: 0 0% 100%;
  --bc: 233.33 27.273% 12.941%;
  --rounded-box: .25rem;
  --rounded-btn: .125rem;
  --rounded-badge: .125rem;
  --animation-btn: 0;
  --animation-input: 0;
  --btn-focus-scale: 1;
}

[data-theme="synthwave"] {
  color-scheme: dark;
  --pf: 320.73 69.62% 55.216%;
  --sf: 197.03 86.592% 51.922%;
  --af: 48 89.041% 45.647%;
  --nf: 253.22 60.825% 15.216%;
  --b2: 253.85 59.091% 23.294%;
  --b3: 253.85 59.091% 20.965%;
  --pc: 320.73 100% 13.804%;
  --sc: 197.03 100% 12.98%;
  --ac: 48 100% 11.412%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 320.73 69.62% 69.02%;
  --s: 197.03 86.592% 64.902%;
  --a: 48 89.041% 57.059%;
  --n: 253.22 60.825% 19.02%;
  --nc: 260 60% 98.039%;
  --b1: 253.85 59.091% 25.882%;
  --bc: 260 60% 98.039%;
  --in: 199.13 86.957% 63.922%;
  --inc: 257.45 63.218% 17.059%;
  --su: 168.1 74.233% 68.039%;
  --suc: 257.45 63.218% 17.059%;
  --wa: 48 89.041% 57.059%;
  --wac: 257.45 63.218% 17.059%;
  --er: 351.85 73.636% 56.863%;
  --erc: 260 60% 98.039%;
}

[data-theme="retro"] {
  color-scheme: light;
  --pf: 2.6667 73.77% 60.863%;
  --sf: 144.62 27.273% 57.569%;
  --af: 49.024 67.213% 60.863%;
  --nf: 41.667 16.822% 33.569%;
  --inc: 221.21 100% 90.667%;
  --suc: 142.13 100% 87.255%;
  --wac: 32.133 100% 8.7451%;
  --erc: 0 100% 90.118%;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 2.6667 73.77% 76.078%;
  --pc: 345 5.2632% 14.902%;
  --s: 144.62 27.273% 71.961%;
  --sc: 345 5.2632% 14.902%;
  --a: 49.024 67.213% 76.078%;
  --ac: 345 5.2632% 14.902%;
  --n: 41.667 16.822% 41.961%;
  --nc: 45 47.059% 80%;
  --b1: 45 47.059% 80%;
  --b2: 45.283 37.063% 71.961%;
  --b3: 42.188 35.955% 65.098%;
  --bc: 345 5.2632% 14.902%;
  --in: 221.21 83.193% 53.333%;
  --su: 142.13 76.216% 36.275%;
  --wa: 32.133 94.619% 43.725%;
  --er: 0 72.222% 50.588%;
  --rounded-box: .4rem;
  --rounded-btn: .4rem;
  --rounded-badge: .4rem;
}

[data-theme="cyberpunk"] {
  color-scheme: light;
  --pf: 344.78 100% 58.353%;
  --sf: 195.12 80.392% 56%;
  --af: 276 74.324% 56.784%;
  --nf: 57.273 100% 10.353%;
  --b2: 56 100% 45%;
  --b3: 56 100% 40.5%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --bc: 56 100% 10%;
  --pc: 344.78 100% 14.588%;
  --sc: 195.12 100% 14%;
  --ac: 276 100% 14.196%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --p: 344.78 100% 72.941%;
  --s: 195.12 80.392% 70%;
  --a: 276 74.324% 70.98%;
  --n: 57.273 100% 12.941%;
  --nc: 56 100% 50%;
  --b1: 56 100% 50%;
  --rounded-box: 0;
  --rounded-btn: 0;
  --rounded-badge: 0;
  --tab-radius: 0;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

[data-theme="valentine"] {
  color-scheme: light;
  --pf: 353.23 73.81% 53.647%;
  --sf: 254.12 86.441% 61.49%;
  --af: 181.41 55.556% 56%;
  --nf: 336 42.857% 38.431%;
  --b2: 318.46 46.429% 80.118%;
  --b3: 318.46 46.429% 72.106%;
  --pc: 353.23 100% 13.412%;
  --sc: 254.12 100% 15.373%;
  --ac: 181.41 100% 14%;
  --inc: 221.21 100% 90.667%;
  --suc: 142.13 100% 87.255%;
  --wac: 32.133 100% 8.7451%;
  --erc: 0 100% 90.118%;
  --rounded-box: 1rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 353.23 73.81% 67.059%;
  --s: 254.12 86.441% 76.863%;
  --a: 181.41 55.556% 70%;
  --n: 336 42.857% 48.039%;
  --nc: 318.46 46.429% 89.02%;
  --b1: 318.46 46.429% 89.02%;
  --bc: 343.64 38.462% 28.039%;
  --in: 221.21 83.193% 53.333%;
  --su: 142.13 76.216% 36.275%;
  --wa: 32.133 94.619% 43.725%;
  --er: 0 72.222% 50.588%;
  --rounded-btn: 1.9rem;
}

[data-theme="halloween"] {
  color-scheme: dark;
  --pf: 31.927 89.344% 41.725%;
  --sf: 271.22 45.794% 33.569%;
  --af: 91.071 100% 26.353%;
  --nf: 180 3.5714% 8.7843%;
  --b2: 0 0% 11.647%;
  --b3: 0 0% 10.482%;
  --bc: 0 0% 82.588%;
  --sc: 271.22 100% 88.392%;
  --ac: 91.071 100% 6.5882%;
  --nc: 180 4.8458% 82.196%;
  --inc: 221.21 100% 90.667%;
  --suc: 142.13 100% 87.255%;
  --wac: 32.133 100% 8.7451%;
  --erc: 0 100% 90.118%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 31.927 89.344% 52.157%;
  --pc: 180 7.3171% 8.0392%;
  --s: 271.22 45.794% 41.961%;
  --a: 91.071 100% 32.941%;
  --n: 180 3.5714% 10.98%;
  --b1: 0 0% 12.941%;
  --in: 221.21 83.193% 53.333%;
  --su: 142.13 76.216% 36.275%;
  --wa: 32.133 94.619% 43.725%;
  --er: 0 72.222% 50.588%;
}

[data-theme="garden"] {
  color-scheme: light;
  --pf: 138.86 15.982% 34.353%;
  --sf: 96.923 37.143% 74.51%;
  --af: 0 67.742% 75.137%;
  --nf: 0 3.9106% 28.078%;
  --b2: 0 4.3478% 81.882%;
  --b3: 0 4.3478% 73.694%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --pc: 138.86 100% 88.588%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 138.86 15.982% 42.941%;
  --s: 96.923 37.143% 93.137%;
  --sc: 96 32.468% 15.098%;
  --a: 0 67.742% 93.922%;
  --ac: 0 21.951% 16.078%;
  --n: 0 3.9106% 35.098%;
  --nc: 0 4.3478% 90.98%;
  --b1: 0 4.3478% 90.98%;
  --bc: 0 3.2258% 6.0784%;
}

[data-theme="forest"] {
  color-scheme: dark;
  --pf: 141.04 71.963% 33.569%;
  --sf: 140.98 74.694% 38.431%;
  --af: 35.148 68.98% 41.569%;
  --nf: 0 9.6774% 4.8627%;
  --b2: 0 12.195% 7.2353%;
  --b3: 0 12.195% 6.5118%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --bc: 0 11.727% 81.608%;
  --sc: 140.98 100% 9.6078%;
  --ac: 35.148 100% 10.392%;
  --nc: 0 6.8894% 81.216%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 141.04 71.963% 41.961%;
  --pc: 140.66 100% 88.039%;
  --s: 140.98 74.694% 48.039%;
  --a: 35.148 68.98% 51.961%;
  --n: 0 9.6774% 6.0784%;
  --b1: 0 12.195% 8.0392%;
  --rounded-btn: 1.9rem;
}

[data-theme="aqua"] {
  color-scheme: dark;
  --pf: 181.79 92.857% 39.529%;
  --sf: 274.41 30.909% 45.49%;
  --af: 47.059 100% 64%;
  --nf: 205.4 53.725% 40%;
  --b2: 218.61 52.511% 38.647%;
  --b3: 218.61 52.511% 34.782%;
  --bc: 218.61 100% 88.588%;
  --sc: 274.41 100% 91.373%;
  --ac: 47.059 100% 16%;
  --nc: 205.4 100% 90%;
  --inc: 221.21 100% 90.667%;
  --suc: 142.13 100% 87.255%;
  --wac: 32.133 100% 8.7451%;
  --erc: 0 100% 90.118%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 181.79 92.857% 49.412%;
  --pc: 181.41 100% 16.667%;
  --s: 274.41 30.909% 56.863%;
  --a: 47.059 100% 80%;
  --n: 205.4 53.725% 50%;
  --b1: 218.61 52.511% 42.941%;
  --in: 221.21 83.193% 53.333%;
  --su: 142.13 76.216% 36.275%;
  --wa: 32.133 94.619% 43.725%;
  --er: 0 72.222% 50.588%;
}

[data-theme="lofi"] {
  color-scheme: light;
  --pf: 0 0% 4.0784%;
  --sf: 0 1.9608% 8%;
  --af: 0 0% 11.922%;
  --nf: 0 0% 0%;
  --btn-text-case: uppercase;
  --border-btn: 1px;
  --tab-border: 1px;
  --p: 0 0% 5.098%;
  --pc: 0 0% 100%;
  --s: 0 1.9608% 10%;
  --sc: 0 0% 100%;
  --a: 0 0% 14.902%;
  --ac: 0 0% 100%;
  --n: 0 0% 0%;
  --nc: 0 0% 100%;
  --b1: 0 0% 100%;
  --b2: 0 0% 94.902%;
  --b3: 0 1.9608% 90%;
  --bc: 0 0% 0%;
  --in: 212.35 100% 47.647%;
  --inc: 0 0% 100%;
  --su: 136.84 72.152% 46.471%;
  --suc: 0 0% 100%;
  --wa: 4.5614 100% 66.471%;
  --wac: 0 0% 100%;
  --er: 325.05 77.6% 49.02%;
  --erc: 0 0% 100%;
  --rounded-box: .25rem;
  --rounded-btn: .125rem;
  --rounded-badge: .125rem;
  --animation-btn: 0;
  --animation-input: 0;
  --btn-focus-scale: 1;
  --tab-radius: 0;
}

[data-theme="pastel"] {
  color-scheme: light;
  --pf: 283.64 21.569% 64%;
  --sf: 351.63 70.492% 70.431%;
  --af: 158.49 54.639% 64.784%;
  --nf: 198.62 43.719% 48.784%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --bc: 0 0% 20%;
  --pc: 283.64 59.314% 16%;
  --sc: 351.63 100% 17.608%;
  --ac: 158.49 100% 16.196%;
  --nc: 198.62 100% 12.196%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 283.64 21.569% 80%;
  --s: 351.63 70.492% 88.039%;
  --a: 158.49 54.639% 80.98%;
  --n: 198.62 43.719% 60.98%;
  --b1: 0 0% 100%;
  --b2: 210 20% 98.039%;
  --b3: 216 12.195% 83.922%;
  --rounded-btn: 1.9rem;
}

[data-theme="fantasy"] {
  color-scheme: light;
  --pf: 296.04 82.813% 20.078%;
  --sf: 200 100% 29.647%;
  --af: 30.894 94.378% 40.941%;
  --nf: 215 27.907% 13.49%;
  --b2: 0 0% 90%;
  --b3: 0 0% 81%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --pc: 296.04 100% 85.02%;
  --sc: 200 100% 87.412%;
  --ac: 30.894 100% 10.235%;
  --nc: 215 62.264% 83.373%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 296.04 82.813% 25.098%;
  --s: 200 100% 37.059%;
  --a: 30.894 94.378% 51.176%;
  --n: 215 27.907% 16.863%;
  --b1: 0 0% 100%;
  --bc: 215 27.907% 16.863%;
}

[data-theme="wireframe"] {
  color-scheme: light;
  --pf: 0 0% 57.725%;
  --sf: 0 0% 57.725%;
  --af: 0 0% 57.725%;
  --nf: 0 0% 73.725%;
  --bc: 0 0% 20%;
  --pc: 0 0% 14.431%;
  --sc: 0 0% 14.431%;
  --ac: 0 0% 14.431%;
  --nc: 0 0% 18.431%;
  --inc: 240 100% 90%;
  --suc: 120 100% 85.02%;
  --wac: 60 100% 10%;
  --erc: 0 100% 90%;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --p: 0 0% 72.157%;
  --s: 0 0% 72.157%;
  --a: 0 0% 72.157%;
  --n: 0 0% 92.157%;
  --b1: 0 0% 100%;
  --b2: 0 0% 93.333%;
  --b3: 0 0% 86.667%;
  --in: 240 100% 50%;
  --su: 120 100% 25.098%;
  --wa: 60 30.196% 50%;
  --er: 0 100% 50%;
  --rounded-box: .2rem;
  --rounded-btn: .2rem;
  --rounded-badge: .2rem;
  --tab-radius: .2rem;
  font-family: Chalkboard, comic sans ms, sanssecondaryerif;
}

[data-theme="black"] {
  color-scheme: dark;
  --pf: 0 1.9608% 16%;
  --sf: 0 1.9608% 16%;
  --af: 0 1.9608% 16%;
  --bc: 0 0% 80%;
  --pc: 0 5.3922% 84%;
  --sc: 0 5.3922% 84%;
  --ac: 0 5.3922% 84%;
  --nc: 0 2.5404% 83.02%;
  --inc: 240 100% 90%;
  --suc: 120 100% 85.02%;
  --wac: 60 100% 10%;
  --erc: 0 100% 90%;
  --border-btn: 1px;
  --tab-border: 1px;
  --p: 0 1.9608% 20%;
  --s: 0 1.9608% 20%;
  --a: 0 1.9608% 20%;
  --b1: 0 0% 0%;
  --b2: 0 0% 5.098%;
  --b3: 0 1.9608% 10%;
  --n: 0 1.2987% 15.098%;
  --nf: 0 1.9608% 20%;
  --in: 240 100% 50%;
  --su: 120 100% 25.098%;
  --wa: 60 100% 50%;
  --er: 0 100% 50%;
  --rounded-box: 0;
  --rounded-btn: 0;
  --rounded-badge: 0;
  --animation-btn: 0;
  --animation-input: 0;
  --btn-text-case: lowercase;
  --btn-focus-scale: 1;
  --tab-radius: 0;
}

[data-theme="luxury"] {
  color-scheme: dark;
  --pf: 0 0% 80%;
  --sf: 218.4 54.348% 14.431%;
  --af: 318.62 21.805% 20.863%;
  --nf: 270 4.3478% 7.2157%;
  --pc: 0 0% 20%;
  --sc: 218.4 100% 83.608%;
  --ac: 318.62 84.615% 85.216%;
  --inc: 202.35 100% 14%;
  --suc: 89.007 100% 10.392%;
  --wac: 53.906 100% 12.706%;
  --erc: 0 100% 14.353%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 0 0% 100%;
  --s: 218.4 54.348% 18.039%;
  --a: 318.62 21.805% 26.078%;
  --n: 270 4.3478% 9.0196%;
  --nc: 37.083 67.29% 58.039%;
  --b1: 240 10% 3.9216%;
  --b2: 270 4.3478% 9.0196%;
  --b3: 270 2.1739% 18.039%;
  --bc: 37.083 67.29% 58.039%;
  --in: 202.35 100% 70%;
  --su: 89.007 61.633% 51.961%;
  --wa: 53.906 68.817% 63.529%;
  --er: 0 100% 71.765%;
}

[data-theme="dracula"] {
  color-scheme: dark;
  --pf: 325.52 100% 58.98%;
  --sf: 264.71 89.474% 62.118%;
  --af: 31.02 100% 56.941%;
  --nf: 229.57 15.033% 24%;
  --b2: 231.43 14.894% 16.588%;
  --b3: 231.43 14.894% 14.929%;
  --pc: 325.52 100% 14.745%;
  --sc: 264.71 100% 15.529%;
  --ac: 31.02 100% 14.235%;
  --nc: 229.57 70.868% 86%;
  --inc: 190.53 100% 15.373%;
  --suc: 135.18 100% 12.941%;
  --wac: 64.909 100% 15.294%;
  --erc: 0 100% 93.333%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 325.52 100% 73.725%;
  --s: 264.71 89.474% 77.647%;
  --a: 31.02 100% 71.176%;
  --n: 229.57 15.033% 30%;
  --b1: 231.43 14.894% 18.431%;
  --bc: 60 30% 96.078%;
  --in: 190.53 96.61% 76.863%;
  --su: 135.18 94.444% 64.706%;
  --wa: 64.909 91.667% 76.471%;
  --er: 0 100% 66.667%;
}

[data-theme="cmyk"] {
  color-scheme: light;
  --pf: 202.72 83.251% 48.157%;
  --sf: 335.25 77.67% 47.686%;
  --af: 56.195 100% 47.843%;
  --nf: 0 0% 8.1569%;
  --b2: 0 0% 90%;
  --b3: 0 0% 81%;
  --bc: 0 0% 20%;
  --pc: 202.72 100% 12.039%;
  --sc: 335.25 100% 91.922%;
  --ac: 56.195 100% 11.961%;
  --nc: 0 0% 82.039%;
  --inc: 192.2 100% 10.431%;
  --suc: 291.06 100% 87.608%;
  --wac: 25.027 100% 11.333%;
  --erc: 3.956 100% 91.137%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 202.72 83.251% 60.196%;
  --s: 335.25 77.67% 59.608%;
  --a: 56.195 100% 59.804%;
  --n: 0 0% 10.196%;
  --b1: 0 0% 100%;
  --in: 192.2 48.361% 52.157%;
  --su: 291.06 48.454% 38.039%;
  --wa: 25.027 84.615% 56.667%;
  --er: 3.956 80.531% 55.686%;
}

[data-theme="autumn"] {
  color-scheme: light;
  --pf: 344.23 95.804% 22.431%;
  --sf: .44444 63.38% 46.588%;
  --af: 27.477 56.021% 50.039%;
  --nf: 22.105 17.117% 34.824%;
  --b2: 0 0% 85.059%;
  --b3: 0 0% 76.553%;
  --bc: 0 0% 18.902%;
  --pc: 344.23 100% 85.608%;
  --sc: .44444 100% 91.647%;
  --ac: 27.477 100% 12.51%;
  --nc: 22.105 100% 88.706%;
  --inc: 186.94 100% 9.9216%;
  --suc: 164.59 100% 8.6275%;
  --wac: 30.141 100% 9.9216%;
  --erc: 353.6 100% 89.765%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 344.23 95.804% 28.039%;
  --s: .44444 63.38% 58.235%;
  --a: 27.477 56.021% 62.549%;
  --n: 22.105 17.117% 43.529%;
  --b1: 0 0% 94.51%;
  --in: 186.94 47.826% 49.608%;
  --su: 164.59 33.636% 43.137%;
  --wa: 30.141 84.19% 49.608%;
  --er: 353.6 79.116% 48.824%;
}

[data-theme="business"] {
  color-scheme: dark;
  --pf: 210 64.103% 24.471%;
  --sf: 200 12.931% 43.608%;
  --af: 12.515 79.512% 47.843%;
  --nf: 212.73 13.58% 12.706%;
  --b2: 0 0% 11.294%;
  --b3: 0 0% 10.165%;
  --bc: 0 0% 82.51%;
  --pc: 210 100% 86.118%;
  --sc: 200 100% 10.902%;
  --ac: 12.515 100% 11.961%;
  --nc: 212.73 28.205% 83.176%;
  --inc: 199.15 100% 88.353%;
  --suc: 144 100% 11.137%;
  --wac: 39.231 100% 12.078%;
  --erc: 6.3415 100% 88.667%;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 210 64.103% 30.588%;
  --s: 200 12.931% 54.51%;
  --a: 12.515 79.512% 59.804%;
  --n: 212.73 13.58% 15.882%;
  --b1: 0 0% 12.549%;
  --in: 199.15 100% 41.765%;
  --su: 144 30.973% 55.686%;
  --wa: 39.231 64.356% 60.392%;
  --er: 6.3415 55.656% 43.333%;
  --rounded-box: .25rem;
  --rounded-btn: .125rem;
  --rounded-badge: .125rem;
}

[data-theme="acid"] {
  color-scheme: light;
  --pf: 302.59 100% 40%;
  --sf: 27.294 100% 40%;
  --af: 72 98.425% 40.157%;
  --nf: 238.42 43.182% 13.804%;
  --b2: 0 0% 88.235%;
  --b3: 0 0% 79.412%;
  --bc: 0 0% 19.608%;
  --pc: 302.59 100% 90%;
  --sc: 27.294 100% 10%;
  --ac: 72 100% 10.039%;
  --nc: 238.42 99.052% 83.451%;
  --inc: 209.85 100% 11.569%;
  --suc: 148.87 100% 11.608%;
  --wac: 52.574 100% 11.451%;
  --erc: .78261 100% 89.02%;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 302.59 100% 50%;
  --s: 27.294 100% 50%;
  --a: 72 98.425% 50.196%;
  --n: 238.42 43.182% 17.255%;
  --b1: 0 0% 98.039%;
  --in: 209.85 91.628% 57.843%;
  --su: 148.87 49.533% 58.039%;
  --wa: 52.574 92.661% 57.255%;
  --er: .78261 100% 45.098%;
  --rounded-box: 1.25rem;
  --rounded-btn: 1rem;
  --rounded-badge: 1rem;
}

[data-theme="lemonade"] {
  color-scheme: light;
  --pf: 88.8 96.154% 24.471%;
  --sf: 60 80.952% 43.765%;
  --af: 62.553 79.661% 70.745%;
  --nf: 238.42 43.182% 13.804%;
  --b2: 0 0% 90%;
  --b3: 0 0% 81%;
  --bc: 0 0% 20%;
  --pc: 88.8 100% 86.118%;
  --sc: 60 100% 10.941%;
  --ac: 62.553 100% 17.686%;
  --nc: 238.42 99.052% 83.451%;
  --inc: 191.61 79.118% 16.902%;
  --suc: 74.458 100% 15.725%;
  --wac: 50.182 100% 15.059%;
  --erc: .98361 100% 16.588%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 88.8 96.154% 30.588%;
  --s: 60 80.952% 54.706%;
  --a: 62.553 79.661% 88.431%;
  --n: 238.42 43.182% 17.255%;
  --b1: 0 0% 100%;
  --in: 191.61 39.241% 84.51%;
  --su: 74.458 76.147% 78.627%;
  --wa: 50.182 87.302% 75.294%;
  --er: .98361 70.115% 82.941%;
}

[data-theme="night"] {
  color-scheme: dark;
  --pf: 198.44 93.204% 47.686%;
  --sf: 234.45 89.474% 59.137%;
  --af: 328.85 85.621% 56%;
  --b2: 222.22 47.368% 10.059%;
  --b3: 222.22 47.368% 9.0529%;
  --bc: 222.22 65.563% 82.235%;
  --pc: 198.44 100% 11.922%;
  --sc: 234.45 100% 14.784%;
  --ac: 328.85 100% 14%;
  --nc: 217.24 75.772% 83.49%;
  --inc: 198.46 100% 9.6078%;
  --suc: 172.46 100% 10.078%;
  --wac: 40.61 100% 12.706%;
  --erc: 350.94 100% 14.235%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 198.44 93.204% 59.608%;
  --s: 234.45 89.474% 73.922%;
  --a: 328.85 85.621% 70%;
  --n: 217.24 32.584% 17.451%;
  --nf: 217.06 30.357% 21.961%;
  --b1: 222.22 47.368% 11.176%;
  --in: 198.46 90.204% 48.039%;
  --su: 172.46 66.008% 50.392%;
  --wa: 40.61 88.172% 63.529%;
  --er: 350.94 94.558% 71.176%;
}

[data-theme="coffee"] {
  color-scheme: dark;
  --pf: 29.583 66.667% 46.118%;
  --sf: 182.4 24.752% 15.843%;
  --af: 194.19 74.4% 19.608%;
  --nf: 300 20% 4.7059%;
  --b2: 306 18.519% 9.5294%;
  --b3: 306 18.519% 8.5765%;
  --pc: 29.583 100% 11.529%;
  --sc: 182.4 67.237% 83.961%;
  --ac: 194.19 100% 84.902%;
  --nc: 300 13.75% 81.176%;
  --inc: 171.15 100% 13.451%;
  --suc: 92.5 100% 12.471%;
  --wac: 43.125 100% 13.725%;
  --erc: 9.7561 100% 14.941%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 29.583 66.667% 57.647%;
  --s: 182.4 24.752% 19.804%;
  --a: 194.19 74.4% 24.51%;
  --n: 300 20% 5.8824%;
  --b1: 306 18.519% 10.588%;
  --bc: 36.667 8.3333% 42.353%;
  --in: 171.15 36.527% 67.255%;
  --su: 92.5 25% 62.353%;
  --wa: 43.125 100% 68.627%;
  --er: 9.7561 95.349% 74.706%;
}

[data-theme="winter"] {
  color-scheme: light;
  --pf: 211.79 100% 40.627%;
  --sf: 246.92 47.273% 34.51%;
  --af: 310.41 49.388% 41.569%;
  --nf: 217.02 92.157% 8%;
  --pc: 211.79 100% 90.157%;
  --sc: 246.92 100% 88.627%;
  --ac: 310.41 100% 90.392%;
  --nc: 217.02 100% 82%;
  --inc: 191.54 100% 15.608%;
  --suc: 181.5 100% 13.255%;
  --wac: 32.308 100% 16.706%;
  --erc: 0 100% 14.431%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 211.79 100% 50.784%;
  --s: 246.92 47.273% 43.137%;
  --a: 310.41 49.388% 51.961%;
  --n: 217.02 92.157% 10%;
  --b1: 0 0% 100%;
  --b2: 216.92 100% 97.451%;
  --b3: 218.82 43.59% 92.353%;
  --bc: 214.29 30.061% 31.961%;
  --in: 191.54 92.857% 78.039%;
  --su: 181.5 46.512% 66.275%;
  --wa: 32.308 61.905% 83.529%;
  --er: 0 63.38% 72.157%;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.avatar.placeholder > div {
  justify-content: center;
  align-items: center;
  display: flex;
}

.btn {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-color: #0000;
  border-color: hsl(var(--n) / var(--tw-border-opacity));
  text-align: center;
  border-radius: var(--rounded-btn, .5rem);
  height: 3rem;
  min-height: 3rem;
  text-transform: uppercase;
  text-transform: var(--btn-text-case, uppercase);
  border-width: var(--border-btn, 1px);
  animation: button-pop var(--animation-btn, .25s) ease-out;
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1em;
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

.btn-disabled, .btn[disabled], .btn.loading, .btn.loading:hover {
  pointer-events: none;
}

.btn.loading:before {
  height: 1rem;
  width: 1rem;
  content: "";
  border-width: 2px;
  border-color: #0000 currentColor currentColor #0000;
  border-radius: 9999px;
  margin-right: .5rem;
  animation: 2s linear infinite spin;
}

@media (prefers-reduced-motion: reduce) {
  .btn.loading:before {
    animation: 10s linear infinite spin;
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.btn-group > input[type="radio"].btn {
  -webkit-appearance: none;
  appearance: none;
}

.btn-group > input[type="radio"].btn:before {
  content: attr(data-title);
}

.form-control {
  flex-direction: column;
  display: flex;
}

.label {
  -webkit-user-select: none;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  padding: .5rem .25rem;
  display: flex;
}

.input {
  height: 3rem;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 1;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.input-group > .input {
  isolation: isolate;
}

.input-group > *, .input-group > .input, .input-group > .select {
  border-radius: 0;
}

.link {
  cursor: pointer;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.btn-outline .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nf, var(--n)) / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
}

.btn-outline.btn-primary .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btn-outline.btn-secondary .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.btn-outline.btn-accent .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
}

.btn-outline .badge.outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nf, var(--n)) / var(--tw-border-opacity));
  background-color: #0000;
}

.btn-outline.btn-primary .badge-outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
  background-color: #0000;
}

.btn-outline.btn-secondary .badge-outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity));
  background-color: #0000;
}

.btn-outline.btn-accent .badge-outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--a) / var(--tw-text-opacity));
  background-color: #0000;
}

.btn-outline.btn-info .badge-outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--in) / var(--tw-text-opacity));
  background-color: #0000;
}

.btn-outline.btn-success .badge-outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--su) / var(--tw-text-opacity));
  background-color: #0000;
}

.btn-outline.btn-warning .badge-outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--wa) / var(--tw-text-opacity));
  background-color: #0000;
}

.btn-outline.btn-error .badge-outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--er) / var(--tw-text-opacity));
  background-color: #0000;
}

.btn-outline:hover .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
}

.btn-outline:hover .badge.outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
}

.btn-outline.btn-primary:hover .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pc) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pc) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
}

.btn-outline.btn-primary:hover .badge.outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pc) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf, var(--p)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btn-outline.btn-secondary:hover .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sc) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sc) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity));
}

.btn-outline.btn-secondary:hover .badge.outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sc) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sf, var(--s)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.btn-outline.btn-accent:hover .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--ac) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--ac) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--a) / var(--tw-text-opacity));
}

.btn-outline.btn-accent:hover .badge.outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--ac) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--af, var(--a)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
}

.btm-nav > * .label {
  font-size: 1rem;
  line-height: 1.5rem;
}

.btn:active:hover, .btn:active:focus {
  transform: scale(var(--btn-focus-scale, .95));
  animation: none;
}

.btn:hover, .btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nf, var(--n)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nf, var(--n)) / var(--tw-bg-opacity));
}

.btn:focus-visible {
  outline: 2px solid hsl(var(--nf));
  outline-offset: 2px;
}

.btn.glass:hover, .btn.glass.btn-active {
  --glass-opacity: 25%;
  --glass-border-opacity: 15%;
}

.btn.glass:focus-visible {
  outline: 2px solid;
}

.btn-outline {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  background-color: #0000;
  border-color: currentColor;
}

.btn-outline:hover, .btn-outline.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--b1) / var(--tw-text-opacity));
}

.btn-outline.btn-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
}

.btn-outline.btn-primary:hover, .btn-outline.btn-primary.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pf, var(--p)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf, var(--p)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btn-outline.btn-secondary {
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity));
}

.btn-outline.btn-secondary:hover, .btn-outline.btn-secondary.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sf, var(--s)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sf, var(--s)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.btn-outline.btn-accent {
  --tw-text-opacity: 1;
  color: hsl(var(--a) / var(--tw-text-opacity));
}

.btn-outline.btn-accent:hover, .btn-outline.btn-accent.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--af, var(--a)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--af, var(--a)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
}

.btn-outline.btn-success {
  --tw-text-opacity: 1;
  color: hsl(var(--su) / var(--tw-text-opacity));
}

.btn-outline.btn-success:hover, .btn-outline.btn-success.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--suc, var(--nc)) / var(--tw-text-opacity));
}

.btn-outline.btn-info {
  --tw-text-opacity: 1;
  color: hsl(var(--in) / var(--tw-text-opacity));
}

.btn-outline.btn-info:hover, .btn-outline.btn-info.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--inc, var(--nc)) / var(--tw-text-opacity));
}

.btn-outline.btn-warning {
  --tw-text-opacity: 1;
  color: hsl(var(--wa) / var(--tw-text-opacity));
}

.btn-outline.btn-warning:hover, .btn-outline.btn-warning.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--wac, var(--nc)) / var(--tw-text-opacity));
}

.btn-outline.btn-error {
  --tw-text-opacity: 1;
  color: hsl(var(--er) / var(--tw-text-opacity));
}

.btn-outline.btn-error:hover, .btn-outline.btn-error.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--erc, var(--nc)) / var(--tw-text-opacity));
}

.btn-disabled, .btn-disabled:hover, .btn[disabled], .btn[disabled]:hover {
  --tw-border-opacity: 0;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-text-opacity: .2;
}

.btn.loading.btn-square:before, .btn.loading.btn-circle:before {
  margin-right: 0;
}

.btn.loading.btn-xl:before, .btn.loading.btn-lg:before {
  height: 1.25rem;
  width: 1.25rem;
}

.btn.loading.btn-sm:before, .btn.loading.btn-xs:before {
  height: .75rem;
  width: .75rem;
}

.btn-group > input[type="radio"]:checked.btn, .btn-group > .btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btn-group > input[type="radio"]:checked.btn:focus-visible, .btn-group > .btn-active:focus-visible {
  outline: 2px solid hsl(var(--p));
}

@keyframes button-pop {
  0% {
    transform: scale(var(--btn-focus-scale, .95));
  }

  40% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }

  50% {
    background-position-y: -2px;
  }

  100% {
    background-position-y: 0;
  }
}

.label-text {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  font-size: .875rem;
  line-height: 1.25rem;
}

.label a:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
}

.input[list]::-webkit-calendar-picker-indicator {
  line-height: 1em;
}

.input-bordered {
  --tw-border-opacity: .2;
}

.input:focus {
  outline: 2px solid hsla(var(--bc) / .2);
  outline-offset: 2px;
}

.input-disabled, .input[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  --tw-text-opacity: .2;
}

.input-disabled::placeholder, .input[disabled]::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.link:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.link:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

@keyframes progress-loading {
  50% {
    left: 107%;
  }
}

@keyframes radiomark {
  0% {
    box-shadow: 0 0 0 12px hsl(var(--b1)) inset, 0 0 0 12px hsl(var(--b1)) inset;
  }

  50% {
    box-shadow: 0 0 0 3px hsl(var(--b1)) inset, 0 0 0 3px hsl(var(--b1)) inset;
  }

  100% {
    box-shadow: 0 0 0 4px hsl(var(--b1)) inset, 0 0 0 4px hsl(var(--b1)) inset;
  }
}

@keyframes rating-pop {
  0% {
    transform: translateY(-.125em);
  }

  40% {
    transform: translateY(-.125em);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes toast-pop {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.btn-group .btn:not(:first-child):not(:last-child), .btn-group.btn-group-horizontal .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.btn-group .btn:first-child:not(:last-child), .btn-group.btn-group-horizontal .btn:first-child:not(:last-child) {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: 0;
  margin-top: 0;
  margin-left: -1px;
}

.btn-group .btn:last-child:not(:first-child), .btn-group.btn-group-horizontal .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.btn-group.btn-group-vertical .btn:first-child:not(:last-child) {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-top: -1px;
  margin-left: 0;
}

.btn-group.btn-group-vertical .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.m-4 {
  margin: 1rem;
}

.ml-0 {
  margin-left: 0;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-1 {
  margin-right: .25rem;
}

.flex {
  display: flex;
}

.h-full {
  height: 100%;
}

.h-5 {
  height: 1.25rem;
}

.w-full {
  width: 100%;
}

.w-5 {
  width: 1.25rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-2 {
  gap: .5rem;
}

.gap-0 {
  gap: 0;
}

.rounded {
  border-radius: .25rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.object-cover {
  object-fit: cover;
}

.p-4 {
  padding: 1rem;
}

.font-serif {
  font-family: ui-serif, Georgia, Cambria, Times New Roman, Times, serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  99% {
    opacity: .01;
    width: 100%;
    height: 100%;
  }

  100% {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    height: 0;
  }
}

.fadeout {
  animation: 3s forwards fadeOut;
}

@media (min-width: 640px) {
  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:flex-row {
    flex-direction: row;
  }
}

/*# sourceMappingURL=index.f6509c90.css.map */
