@tailwind base;
@tailwind components;
@tailwind utilities;


@-webkit-keyframes fadeOut {
    0% { opacity: 1;}
    99% { opacity: 0.01;width: 100%; height: 100%;}
    100% { opacity: 0;width: 0; height: 0;}
}
@keyframes fadeOut {
    0% { opacity: 1;}
    80% { opacity: 0;}
    100% { opacity: 0; height: 0;}
}

.fadeout{
    -webkit-animation: fadeOut 3s;
    animation: fadeOut 3s;
    animation-fill-mode: forwards;
}